<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="buttons justify-content-start mt-2">
          <div
            class="button primary sm"

            @click="() => {
              if(createNewResource) {
                createNewResource = false
                newResource = {
                  label: '',
                  active: true,
                  component: null ,
                  content: null,
                }
              } else {
                createNewResource = true
              }
            }"
          >
            <b-icon icon="plus" /> Adicionar Recurso
          </div>
        </div>
      </b-col>
      <b-col v-if="createNewResource">
        <b-form
          style="text-align: left;"
          @submit="newResourceSave"
        >
          <b-form-group
            id="label"
            label="Nome *"
            label-for="label"
          >
            <b-form-input
              id="label"
              v-model="newResource.label"
              type="text"
              placeholder="Nome do Recurso"
              required
            />
          </b-form-group>

          <b-form-group
            id="resourceType"
            label="Tipo de Recurso *"
            label-for="resourceType"
          >
            <b-form-select
              id="resourceType"
              v-model="newResource.type"
              label-field="label"
              text-field="label"
              value-field="code"
              :options="resourceTypes"
              @change="(value) => {
                if(value === 'report') {
                  newResource.format = 'document'
                  newResource.industries = industries.map(el => (el.code))
                }
              }"
            />
          </b-form-group>

          <b-form-group
            id="sector"
            label="Setor *"
            label-for="sector"
          >
            <b-form-checkbox-group
              id="sector"
              v-model="newResource.industries"
              label-field="label"
              text-field="label"
              value-field="code"
              :disabled="newResource.type === 'report' || newResource.type === null"
              :options="industries"
              size="md"
            />
          </b-form-group>

          <b-form-group
            v-if="newResource.type !== 'report' && newResource.type !== null"
            id="resourceFormat"
            :label="newResource.type === 'event' ? 'Formato' : 'Formato *'"
            label-for="resourceFormat"
          >
            <b-form-select
              id="resourceFormat"
              v-model="newResource.format"
              label-field="label"
              text-field="label"
              value-field="code"
              :options="[{code: null, label: ''}, ...resourceFormats]"
            />
          </b-form-group>

          <b-form-group
            v-if="newResource.format === 'link' || newResource.format === 'video'"
            id="link"
            label="Link *"
            label-for="link"
          >
            <b-form-input
              id="link"
              v-model="newResource.link"
              type="text"
              placeholder="Link"
              :required="newResource.type === 'event' ? false : true"
            />
          </b-form-group>

          <b-form-group
            v-if="newResource.format === 'document'"
            id="document"
            label="Documento *"
            label-for="document"
          >
            <b-form-file
              v-model="newResource.document"
              :name="newResource.document ? resource.document.name : ''"
              placeholder="Selecione um ficheiro ou mova-o para aqui..."
              drop-placeholder="Largue o ficheiro aqui..."
              required
            />
          </b-form-group>
          <b-form-group
            v-if="newResource.format === 'document'"
            id="document"
            label="Flip Html 5 Link"
            label-for="document"
          >
            <b-form-input
              id="link"
              v-model="newResource.external_link"
              type="text"
              placeholder="Link"
            />
          </b-form-group>
          <b-form-group
            v-if="newResource.format === 'document' || newResource.format === 'video' || newResource.format === 'link'"
            id="thumbnail"
            label="Thumbnail *"
            label-for="thumbnail"
          >
            <b-form-file
              v-model="newResource.thumbnail"
              required
              :name="newResource.thumbnail ? newResource.thumbnail.name : ''"
              placeholder="Selecione um ficheiro ou mova-o para aqui..."
              drop-placeholder="Largue o ficheiro aqui..."
              accept=".jpg, .png, .svg"
            />
          </b-form-group>
          <b-form-group
            v-if="newResource.type === 'event'"
            id="event_type"
            label="Tipo de Evento *"
            label-for="event_type"
          >
            <b-form-select
              id="event_type"
              v-model="newResource.event_type"
              label-field="label"
              text-field="label"
              value-field="code"
              required
              :options="eventTypes"
            />
          </b-form-group>
          <b-form-group
            v-if="newResource.type === 'event'"
            id="event_sub_type"
            label="Formato do Evento *"
            label-for="event_sub_type"
          >
            <b-form-select
              id="event_sub_type"
              v-model="newResource.event_sub_type"
              label-field="label"
              text-field="label"
              value-field="code"
              required
              :options="eventSubTypes"
            />
          </b-form-group>
          <b-form-group
            v-if="newResource.type === 'event'"
            id="event_responsible"
            label="Responsável *"
            label-for="event_responsible"
          >
            <b-form-input
              id="event_responsible"
              v-model="newResource.event_responsible"
              type="text"
              placeholder="Responsável"
              required
            />
          </b-form-group>

          <b-form-group
            v-if="newResource.type === 'event' && (newResource.event_sub_type === 'onsite' || newResource.event_sub_type === 'onsite_online')"
            id="event_registered"
            label="Resgistos"
            label-for="event_registered"
          >
            <b-form-input
              id="event_registered"
              v-model="newResource.event_registered"
              type="number"
              placeholder="Registos"
            />
          </b-form-group>
          <b-form-group
            v-if="newResource.type === 'event' && (newResource.event_sub_type === 'onsite' || newResource.event_sub_type === 'onsite_online')"
            id="event_atendees"
            label="Presenças"
            label-for="event_atendees"
          >
            <b-form-input
              id="event_atendees"
              v-model="newResource.event_atendees"
              type="number"
              placeholder="Presenças"
            />
          </b-form-group>

          <b-form-group
            v-if="newResource.type === 'event'"
            id="event_date"
            label="Data *"
            label-for="event_date"
          >
            <b-form-datepicker
              id="event_date-datepicker"
              v-model="newResource.event_date"
              label-help=""
              boundary="window"
              placeholder="00/00/0000"
              locale="pt"
              label-no-date-selected="Selecionar uma data"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-bind="labels"
              required
            />
          </b-form-group>

          <div>* Campo de preenchimento obrigatório</div>
          <div class="buttons justify-content-start">
            <button
              class="button primary sm"
              type="submit"
              variant="primary"
            >
              <b-icon
                icon="cloud-upload"
              /> Guardar
            </button>
            <button
              class="button warning sm"
              variant="danger"
              @click="() => {
                createNewResource = false
                newResource = {
                  label: '',
                  industries: industries.map((el) => (el.code)),
                  type: null,
                  format: null,
                  link: null,
                  external_link: null,
                  document: null,
                  thumbnail: null,
                  event_type: null,
                  event_sub_type: null,
                  event_responsible: null,
                  event_registered: null,
                  event_atendees: null,
                  event_views: null,
                  event_date: null,
                }
              }"
            >
              <b-icon
                icon="x"
              /> Cancelar
            </button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-input-group
          size="sm"
          class="mb-3"
        >
          <template #prepend>
            <b-input-group-text>
              <b-icon
                icon="search"
                scale="0.8"
              />
            </b-input-group-text>
          </template>
          <b-form-input
            id="filter-input"
            v-model="filterResources"
            type="search"
            placeholder="Pesquisar..."
          />

          <b-input-group-append>
            <b-button
              :disabled="!filterResources"
              @click="filterResources = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-group label="Tipos de recurso">
          <b-form-checkbox
            v-if="filters.types"
            :checked="filters.types.length === resourceTypes.length ? true : false"
            :checked-value="true"
            :unchecked-value="false"
            @change="(value) => {
              if(!value) {
                filters = { ...filters, types: [] };
              } else {
                filters = { ...filters, types: resourceTypes.map((el) => el.code) };
              }
            }"
          >
            Selecionar Todos
          </b-form-checkbox>
          <b-form-checkbox-group
            v-model="filters.types"
            :options="resourceTypes"
            value-field="code"
            text-field="label"
            @change="updateFilterTypes"
          />
        </b-form-group>
        <b-form-group
          label="Formatos"
        >
          <b-form-checkbox
            v-if="filters.formats"
            :checked="filters.formats.length === resourceFormats.length ? true : false"
            :checked-value="true"
            :unchecked-value="false"
            @change="(value) => {
              if(!value) {
                filters = { ...filters, formats: [] };
              } else {
                filters = { ...filters, formats: resourceFormats.map((el) => el.code) };
              }
            }"
          >
            Selecionar Todos
          </b-form-checkbox>
          <b-form-checkbox-group
            v-model="filters.formats"
            :options="resourceFormats"
            value-field="code"
            text-field="label"
            @change="updateFilterFormats"
          />
        </b-form-group>
        <b-form-group label="Setores">
          <b-form-checkbox
            v-if="filters.industries"
            :checked="filters.industries.length === industries.length ? true : false"
            :checked-value="true"
            :unchecked-value="false"
            @change="(value) => {
              if(!value) {
                filters = { ...filters, industries: [] };
              } else {
                filters = { ...filters, industries: industries.map((el) => el.code) };
              }
            }"
          >
            Selecionar Todos
          </b-form-checkbox>
          <b-form-checkbox-group
            v-model="filters.industries"
            :options="industries"
            value-field="code"
            text-field="label"
            @change="updateFilterIndustries"
          />
        </b-form-group>
        <b-table
          v-if="resources"
          id="resources"
          :items="resources.filter((el) => {
            if(filters.types) {
              if(!filters.types.includes(el.type)) {
                return false
              }
            }
            if(filters.formats) {
              if(!filters.formats.includes(el.format)) {
                return false
              }
            }
            if(filters.industries) {
              if(!filters.industries.filter(industry => el.industries.includes(industry))) {
                return false
              }
            }
            return true
          })"
          :per-page="perPageResources"
          :current-page="currentPageResources"
          :fields="fieldsResources"
          :filter="filterResources"
          :filter-included-fields="filterOnResources"
          borderless
          hover
          small
        >
          <template
            #cell(selected)="{ rowSelected }"
          >
            <template
              v-if="rowSelected"
            >
              <b-form-checkbox
                :checked="rowSelected"
                value="true"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :checked="rowSelected"
              />
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="buttons m-0 p-0 justify-content-start buttons-group">
              <div
                class="button primary xs"
                @click="row.toggleDetails"
              >
                <b-icon
                  scale="1.2"
                  :icon="row.detailsShowing ? 'eye-slash' : 'eye'"
                />
              </div>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <div style=" align-items: flex-start; display: flex; flex-direction: column;">
                <div
                  v-if="!editResource || editResourceId !== row.item['id'] "
                  style=" align-items: flex-start; display: flex; flex-direction: column;"
                >
                  <div
                    style="order: 1;"
                  >
                    <b>Nome:</b> {{ row.item['label'] }}
                  </div>
                  <div
                    v-if=" row.item['created_at']"
                    style="order: 8;"
                  >
                    <b>Criado em:</b> {{ row.item['created_at'].split("T")[0].split("-").reverse().join("/") }}
                  </div>
                  <div
                    class="buttons"
                    style="order: 9;"
                  >
                    <div
                      class="button primary sm"
                      @click="setEditResource(row.item['id'])"
                    >
                      <b-icon icon="pencil" /> Editar
                    </div>
                    <div
                      v-if="row.item.active"
                      class="button danger sm"
                      @click="() => {
                        deleteResourceId = row.item['id'];
                        $bvModal.show('deleteResource')
                      }"
                    >
                      <b-icon icon="trash" /> Desativar
                    </div>
                    <div
                      v-else
                      class="button success sm"
                      @click="() => {
                        deleteResourceId = row.item['id'];
                        $bvModal.show('activeResource')
                      }"
                    >
                      <b-icon icon="check" /> Ativar
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="width: 100%;"
                >
                  <b-form
                    v-if="resource && editResource && editResourceId === row.item['id']"
                    style="text-align: left;"
                    @submit="resourceSave"
                  >
                    <b-form-group
                      id="label"
                      label="Nome *"
                      label-for="label"
                    >
                      <b-form-input
                        id="label"
                        v-model="resource.label"
                        type="text"
                        placeholder="Nome do Recurso"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      id="resourceType"
                      label="Tipo de Recurso *"
                      label-for="resourceType"
                    >
                      <b-form-select
                        id="resourceType"
                        v-model="resource.type"
                        label-field="label"
                        text-field="label"
                        value-field="code"
                        :options="resourceTypes"
                        @change="(value) => {
                          if(value === 'report') {
                            resource.format = 'document'
                            resource.industries = industries.map(el => (el.code))
                          }
                        }"
                      />
                    </b-form-group>

                    <b-form-group
                      id="sector"
                      label="Setor *"
                      label-for="sector"
                    >
                      <b-form-checkbox-group
                        id="sector"
                        v-model="resource.industries"
                        label-field="label"
                        text-field="label"
                        value-field="code"
                        :disabled="resource.type === 'report' || resource.type === null"
                        :options="industries"
                        size="md"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="resource.type !== 'report' && resource.type !== null"
                      id="resourceFormat"
                      :label="resource.type === 'event' ? 'Formato' : 'Formato *'"
                      label-for="resourceFormat"
                    >
                      <b-form-select
                        id="resourceFormat"
                        v-model="resource.format"
                        label-field="label"
                        text-field="label"
                        value-field="code"
                        :options="[{code: null, label: ''}, ...resourceFormats]"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="resource.format === 'link' || resource.format === 'video'"
                      id="link"
                      label="Link *"
                      label-for="link"
                    >
                      <b-form-input
                        id="link"
                        v-model="resource.link"
                        type="text"
                        placeholder="Link"
                        :required="resource.type === 'event' ? false : true"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="resource.format === 'document'"
                      id="document"
                      label="Documento *"
                      label-for="document"
                    >
                      <b-form-file
                        v-model="resource.document"
                        :name="resource.document ? resource.document.name : ''"
                        placeholder="Selecione um ficheiro ou mova-o para aqui..."
                        drop-placeholder="Largue o ficheiro aqui..."
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="resource.format === 'document'"
                      id="document"
                      label="Flip Html 5 Link"
                      label-for="document"
                    >
                      <b-form-input
                        id="link"
                        v-model="resource.external_link"
                        type="text"
                        placeholder="Link"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="resource.format === 'document' || resource.format === 'video' || resource.format === 'link'"
                      id="thumbnail"
                      label="Thumbnail *"
                      label-for="thumbnail"
                    >
                      <b-form-file
                        v-model="resource.thumbnail"
                        :name="resource.thumbnail ? resource.thumbnail.name : ''"
                        placeholder="Selecione um ficheiro ou mova-o para aqui..."
                        drop-placeholder="Largue o ficheiro aqui..."
                        accept=".jpg, .png, .svg"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="resource.type === 'event'"
                      id="event_type"
                      label="Tipo de Evento *"
                      label-for="event_type"
                    >
                      <b-form-select
                        id="event_type"
                        v-model="resource.event_type"
                        label-field="label"
                        text-field="label"
                        value-field="code"
                        required
                        :options="eventTypes"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="resource.type === 'event'"
                      id="event_sub_type"
                      label="Formato do Evento *"
                      label-for="event_sub_type"
                    >
                      <b-form-select
                        id="event_sub_type"
                        v-model="resource.event_sub_type"
                        label-field="label"
                        text-field="label"
                        value-field="code"
                        required
                        :options="eventSubTypes"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="resource.type === 'event'"
                      id="event_responsible"
                      label="Responsável *"
                      label-for="event_responsible"
                    >
                      <b-form-input
                        id="event_responsible"
                        v-model="resource.event_responsible"
                        type="text"
                        placeholder="Responsável"
                        required
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="resource.type === 'event' && (resource.event_sub_type === 'onsite' || resource.event_sub_type === 'onsite_online')"
                      id="event_registered"
                      label="Resgistos"
                      label-for="event_registered"
                    >
                      <b-form-input
                        id="event_registered"
                        v-model="resource.event_registered"
                        type="number"
                        placeholder="Registos"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="resource.type === 'event' && (resource.event_sub_type === 'onsite'|| resource.event_sub_type === 'onsite_online')"
                      id="event_atendees"
                      label="Presenças"
                      label-for="event_atendees"
                    >
                      <b-form-input
                        id="event_atendees"
                        v-model="resource.event_atendees"
                        type="number"
                        placeholder="Presenças"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="resource.type === 'event'"
                      id="event_date"
                      label="Data *"
                      label-for="event_date"
                    >
                      <b-form-datepicker
                        id="event_date-datepicker"
                        v-model="resource.event_date"
                        label-help=""
                        boundary="window"
                        placeholder="00/00/0000"
                        locale="pt"
                        label-no-date-selected="Selecionar uma data"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        v-bind="labels"
                        required
                      />
                    </b-form-group>

                    <div>* Campo de preenchimento obrigatório</div>
                    <div class="buttons justify-content-start">
                      <button
                        class="button primary sm"
                        type="submit"
                        variant="primary"
                      >
                        <b-icon icon="cloud-upload" /> Guardar
                      </button>
                      <button
                        class="button warning sm"
                        variant="danger"
                        @click="() => {
                          editResource = false
                          editResourceId = null
                        }"
                      >
                        <b-icon
                          icon="x"
                        /> Cancelar
                      </button>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPageResources"
          :total-rows="rowsResources"
          :per-page="perPageResources"
          aria-controls="resources"
        />
      </b-col>
    </b-row>
    <b-modal
      id="deleteResource"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="desativar recurso"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Desativar Recurso
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende desativar este recurso?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteResourceId = null
              $bvModal.hide('deleteResource');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button danger sm"
            type="submit"
            @click="deleteResource"
          >
            <b-icon icon="trash" />
            Desativar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="activeResource"
      header-class="border-bottom-0"
      footer-class="border-top-0"
      hide-header
      centered
      title="ativar recurso"
      hide-footer
      size="lg"
    >
      <div>
        <div class="title">
          Ativar Recurso
        </div>

        <div class="text-dark text-center">
          Tem a certeza que pretende ativar este recurso?
        </div>

        <div class="buttons">
          <button
            class="button secondary sm"
            type="submit"
            @click="() => {
              deleteResourceId = null
              $bvModal.hide('activeResource');
            }"
          >
            <b-icon icon="x" />
            Cancelar
          </button>
          <button
            class="button success sm"
            type="submit"
            @click="activeResource"
          >
            <b-icon icon="check" />
            Ativar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  get, postFile, put,
} from '../../../services/api';

export default {
  name: 'ResourcesTab',
  props: {
    setLoading: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      labels: {
        labelPrevDecade: 'Década anterior',
        labelPrevYear: 'Ano anterior',
        labelPrevMonth: 'Mês anterior',
        labelCurrentMonth: 'Mês atual',
        labelNextMonth: 'Próximo mês',
        labelNextYear: 'Próximo ano',
        labelNextDecade: 'Próxima década',
        labelToday: 'Hoje',
        labelSelected: 'Data selecionada',
        labelNoDateSelected: 'Sem data selecionada',
        labelCalendar: 'Calendário',
        labelNav: 'Navegação no calendário',
        labelHelp: 'Use as setas do teclado para navegar pelo calendário',
        labelResetButton: 'Limpar',
        resetButtonVariant: 'primary',
        labelTodayButton: 'Hoje',
        todayButtonVariant: 'primary',
        labelCloseButton: 'Fechar',
      },
      filters: [],
      currentPageResources: 1,
      filterOnResources: ['label'],
      perPageResources: 10,
      resources: null,
      deleteResourceId: null,
      filterResources: null,
      resourceTypes: [],
      industries: [],
      resourceFormats: [],
      eventTypes: [],
      eventSubTypes: [],
      fieldsResources: [
        {
          key: 'label', sortable: true, label: 'Nome', thStyle: { width: '20%' },
        },
        {
          key: 'type',
          sortable: true,
          label: 'Tipo',
          thStyle: { width: '15%' },
          formatter: (value) => this.resourceTypes.find((type) => type.code === value)?.label,
        },
        {
          key: 'format',
          sortable: true,
          label: 'Formato',
          thStyle: { width: '15%' },
          formatter: (value) => this.resourceFormats.find((format) => format.code === value)?.label,
        },
        {
          key: 'industries',
          sortable: true,
          label: 'Setores',
          thStyle: { width: '30%' },
          formatter: (value) => value.map((el) => this.industries.find((industry) => industry.code === el).label).join(', '),
        },
        {
          key: 'active',
          sortable: true,
          label: 'Ativo',
          formatter: (value) => (value ? 'Sim' : 'Não'),
          thStyle: { width: '10%' },
        },
        {
          key: 'interactionsCount',
          sortable: true,
          label: 'Nº de interações',
          thStyle: { width: '10%' },
        },
        { key: 'actions', label: 'Ações', thStyle: { width: '10%' } },
      ],
      createNewResource: false,
      editResource: false,
      editResourceId: null,
      newResource: {
        label: '',
        industries: [],
        type: null,
        format: null,
        link: null,
        document: null,
        thumbnail: null,
        event_type: null,
        event_sub_type: null,
        event_responsible: null,
        event_registered: null,
        event_atendees: null,
        event_views: null,
        event_date: null,
      },
      resource: null,
    };
  },
  computed: {
    rowsResources() {
      if (this.resources) {
        return this.resources.filter((el) => {
          if (this.filters.types) {
            if (!this.filters.types.includes(el.type)) {
              return false;
            }
          }
          if (this.filters.formats) {
            if (!this.filters.formats.includes(el.format)) {
              return false;
            }
          }
          if (this.filters.industries) {
            if (!this.filters.industries.filter((industry) => el.industries.includes(industry))) {
              return false;
            }
          }
          return true;
        }).length;
      }
      return 0;
    },
  },
  async created() {
    this.setLoading(true);
    await Promise.all([
      this.getResources(),
      this.loadResourceTypes(),
      this.loadResourceFormats(),
      this.loadIndustries(),
      this.loadEventTypes(),
      this.loadEventSubTypes(),
    ]);
    this.setLoading(false);
  },
  methods: {
    updateFilterTypes(value) {
      this.filters = { ...this.filters, types: value };
    },
    updateFilterFormats(value) {
      this.filters = { ...this.filters, formats: value };
    },
    updateFilterIndustries(value) {
      this.filters = { ...this.filters, industries: value };
    },
    uploadDocument(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.newResource.document = reader.result;
      };
    },
    uploadThumbnail(value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.newResource.thumbnail = reader.result;
      };
    },
    setEditResource(id) {
      this.resource = { ...this.resources.find((el) => el.id === id) };

      this.editResourceId = id;
      this.editResource = true;
    },
    async loadResourceTypes() {
      await get('results/resource-types/', { all: true }).then((response) => {
        this.resourceTypes = response.data;
        this.filters.types = response.data.map((el) => el.code);
      });
    },
    async loadResourceFormats() {
      await get('results/resource-formats/', { all: true }).then((response) => {
        this.resourceFormats = response.data;
        this.filters.formats = response.data.map((el) => el.code);
      });
    },
    async loadIndustries() {
      await get('results/industries/', { all: true }).then((response) => {
        this.industries = response.data;
        this.newResource.industries = this.industries.map((el) => (el.code));
        this.filters.industries = response.data.map((el) => el.code);
      });
    },
    async loadEventTypes() {
      await get('results/event-types/', { all: true }).then((response) => {
        this.eventTypes = response.data;
      });
    },
    async loadEventSubTypes() {
      await get('results/event-sub-types/', { all: true }).then((response) => {
        this.eventSubTypes = response.data;
      });
    },
    async getResources() {
      await get('results/resources/', { all: true }).then((response) => {
        this.resources = response.data.map((el) => (
          {
            ...el,
            industries: el.industries.map((industry) => industry.roadmap.code),
          }
        ));
      });
    },
    async activeResource() {
      await put(`results/resources/${this.deleteResourceId}`, {
        active: 1,
      });
      this.deleteResourceId = null;
      this.getResources();
      this.$bvModal.hide('activeResource');
    },
    async deleteResource() {
      await put(`results/resources/${this.deleteResourceId}`, {
        active: 0,
      });
      this.deleteResourceId = null;
      this.getResources();
      this.$bvModal.hide('deleteResource');
    },
    async resourceSave(event) {
      this.setLoading(true);
      event.preventDefault();

      const formData = new FormData();
      if (this.resource.document) {
        formData.append('files[]', this.resource.document, `DOC-${this.resource.document.name}`);
      }
      if (this.resource.thumbnail) {
        formData.append('files[]', this.resource.thumbnail, `THUMB-${this.resource.thumbnail.name}`);
      }
      formData.append('attributes', JSON.stringify(
        {
          id: this.editResourceId,
          label: this.resource.label,
          industries: this.resource.industries,
          type: this.resource.type,
          format: this.resource.format,
          link: this.resource.link && this.resource.link.split('v=').length > 1 && this.resource.format === 'video' ? this.resource.link.split('v=')[1].split('&')[0] : this.resource.link,
          external_link: this.resource.external_link,
          event_type: this.resource.event_type,
          event_sub_type: this.resource.event_sub_type,
          event_responsible: this.resource.event_responsible,
          event_registered: this.resource.event_registered,
          event_atendees: this.resource.event_atendees,
          event_views: this.resource.event_views,
          event_date: this.resource.event_date,
        },
      ));

      await postFile(`results/resources/${this.editResourceId}`, formData);
      this.editResource = false;
      this.editResourceId = null;
      await this.getResources();
      this.setLoading(false);
    },
    async newResourceSave(event) {
      event.preventDefault();
      this.setLoading(true);

      const formData = new FormData();
      if (this.newResource.document) {
        formData.append('files[]', this.newResource.document, `DOC-${this.newResource.document.name}`);
      }
      if (this.newResource.thumbnail) {
        formData.append('files[]', this.newResource.thumbnail, `THUMB-${this.newResource.thumbnail.name}`);
      }
      formData.append('attributes', JSON.stringify(
        {
          id: -1,
          label: this.newResource.label,
          industries: this.newResource.industries,
          type: this.newResource.type,
          format: this.newResource.format,
          link: this.newResource.link && this.newResource.link.split('v=').length > 0 && this.newResource.format === 'video' ? this.newResource.link.split('v=')[1] : this.newResource.link,
          event_type: this.newResource.event_type,
          event_sub_type: this.newResource.event_sub_type,
          event_responsible: this.newResource.event_responsible,
          event_registered: this.newResource.event_registered,
          event_atendees: this.newResource.event_atendees,
          event_views: this.newResource.event_views,
          event_date: this.newResource.event_date,
        },
      ));

      await postFile('results/resources/', formData);
      this.createNewResource = false;
      this.newResource = {
        label: '',
        industries: this.industries.map((el) => (el.code)),
        type: null,
        format: null,
        link: null,
        external_link: null,
        document: null,
        thumbnail: null,
        event_type: null,
        event_sub_type: null,
        event_responsible: null,
        event_registered: null,
        event_atendees: null,
        event_views: null,
        event_date: null,
      };
      await this.getResources();
      this.setLoading(false);
    },
  },
};
</script>
